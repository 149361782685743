import { createApp, h } from "vue";
import App from './App.vue';
import router from './router';
import { store } from '@/store';
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Vueform from '@vueform/vueform'
import vueformConfig from '../vueform.config.js'

const app = createApp({
  render: () => h(App),
});

app.use(router);
app.use(store);
app.use(Vueform, vueformConfig);
app.mount("#app");
