import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Landing = _resolveComponent("Landing")!
  const _component_Snackbar = _resolveComponent("Snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Landing, {
      ref: "landingComponent",
      active: _ctx.landingActive,
      changes: _ctx.getChanges,
      onView: _ctx.openView,
      onRefresh: _ctx.refreshLanding,
      onBack: _ctx.goBack,
      onMessage: _ctx.showMessage
    }, null, 8, ["active", "changes", "onView", "onRefresh", "onBack", "onMessage"]),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentSlideComponent), {
      active: _ctx.landingActive,
      data: _ctx.getData,
      currentSlide: _ctx.getCurrentSlide,
      onBack: _ctx.goBack,
      onRefresh: _ctx.refreshLanding,
      onMessage: _ctx.showMessage,
      onCallConfirmDialog: _ctx.handleConfirmDialog,
      onAttachments: _ctx.openAttachments
    }, null, 40, ["active", "data", "currentSlide", "onBack", "onRefresh", "onMessage", "onCallConfirmDialog", "onAttachments"])),
    _createVNode(_component_Snackbar, {
      message: _ctx.getSnackbarMessage,
      messages: _ctx.getSnackbarMessages
    }, null, 8, ["message", "messages"])
  ], 64))
}