import { defineComponent } from "vue";
import axiosInstance from "../../axiosConfig";
import Dialog from "@/components/Dialog/Dialog.vue";

export default defineComponent({
  components: { Dialog },
  data() {
    return {
      checklistData: this.data,
      isModelOpen: false,
      isDialogActive: false,
      pieceId: null,
    };
  },
  props: ["data"],
  emits: ["message", "back", "forward"],
  computed: {
    dialogActive(): boolean {
      return this.isDialogActive;
    },
    hasPieces() {
      return this.checklistData.pieces.length > 0;
    },
  },
  methods: {
    openModel() {
      this.isModelOpen = true;
    },
    closeModal() {
      this.isModelOpen = false;
    },
    openDialog(pieceId: number) {
      this.isDialogActive = true;
      this.pieceId = pieceId;
    },
    closeDialog(): void {
      this.isDialogActive = false;
    },
    async confirmDialog() {
      this.isDialogActive = false;
      const res = await axiosInstance
        .delete(`/deletePieceJointe/${this.pieceId}`)
        .catch(() =>
          this.$emit("message", "Échec de la suppression de la pièce")
        );
      if (res.status === 200) {
        this.checklistData.pieces = this.checklistData.pieces.filter(
          (piece: any) => piece.id !== this.pieceId
        );
        this.$emit("message", "Pièce supprimée avec succès");
      }
    },
    fileType(filename: string) {
      const ext = filename.split(".").pop();
      const types = {
        image: ["jpg", "jpeg", "png", "svg"],
        pdf: ["pdf"],
        excel: ["xls", "xlsx"],
      };
      const type = Object.keys(types).find((key) => types[key].includes(ext));
      return type ? type : "file";
    },
    getName(filename: string) {
      return filename.split("_").slice(1).join(".");
    },
    updateData(piece) {
      this.checklistData.pieces.push(piece);
      this.$emit("message", "Pièce ajoutée avec succès");
    },
  },
});
