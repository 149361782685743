
import { Options, Vue } from 'vue-class-component';
import { markRaw } from 'vue';
import { debounce } from '@/helpers';
import { ChecklistData } from '@/types';

import Landing from '../Landing/Landing.vue';
import View from '../View/View.vue';
import Snackbar from '../Snackbar/Snackbar.vue';
import Attachments from '../Attachments/Attachments.vue';

@Options({
	components: { Landing, View, Snackbar },
	data() {
		return {
			appContainer: null,
			checklistData: null,
			container: null,
			currentSlide: null,
			landingActive: true,
			landingRefresh: 0,
			slides: document.getElementsByClassName('mdf-slide'),
			slideWidth: 0,
			snackbarMessage: '',
			snackbarMessages: 0,
			styles: null,
		};
	},
	methods: {
		openView(data: ChecklistData) {
			// Show View slide with the given data.
			this.checklistData = data;
			this.currentSlide = markRaw(View);
			this.goForward();
		},
		goForward() {
			// Move forward, away from the Landing slide.
			this.slideLeft();
			this.landingActive = false;
		},
		goBack() {
			// Move back to the Landing slide.
			this.slideRight();
			this.landingActive = true;
			setTimeout(() => (this.currentSlide = null), 360);
		},
		showMessage(message: string) {
			// Show the given Snackbar message.
			this.snackbarMessages++;
			this.snackbarMessage = message;
		},
		refreshLanding() {
			// Refresh the Landing slide.
			this.landingRefresh++;
		},
		slideLeft() {
			// Move all slides to the left.
			this.container.style.transform = `translateX(-${this.slideWidth}px)`;
		},
		slideRight() {
			// Move all slides to the right.
			this.container.style.transform = 'translateX(0px)';
		},
		calcWidth() {
			// Get the CSS properties for the first slide.
			this.styles = window.getComputedStyle((this.container as HTMLElement).firstElementChild as HTMLElement);

			// Calculate the proper width.
			this.slideWidth = parseFloat(this.styles.width) + parseFloat(this.styles.marginRight);

			// Make sure to properly position the currently displayed slide.
			if (this.container.style.transform.includes('-')) {
				this.container.style.transform = `translateX(-${this.slideWidth}px)`;
			}
		},
		handleConfirmDialog(id: number) {
			this.$refs.landingComponent.dialogId = id;
			this.$refs.landingComponent.confirmDialog(1);
		},
		openAttachments(data) {
			// Show View slide with the given data.
			this.currentSlide = markRaw(Attachments);
			this.goForward();
		},
	},
	computed: {
		getData() {
			return this.checklistData;
		},
		getChanges(): number {
			return this.landingRefresh;
		},
		currentSlideComponent() {
			return this.currentSlide;
		},
		isLandingActive(): boolean {
			return this.landingActive;
		},
		getSnackbarMessage(): string {
			return this.snackbarMessage;
		},
		getSnackbarMessages(): number {
			return this.snackbarMessages;
		},
		getCurrentSlide() {
			return this.currentSlide;
		}
	},
	mounted() {
		this.$nextTick(() => {
			// Set the app container element.
			this.appContainer = document.querySelector('.mdf-app');

			// Set the slides container element.
			this.container = (this.$el as HTMLElement).parentElement;
			// Calculate the needed slides width.
			this.calcWidth();

			// Re-calculate slide width on window resize.
			window.onresize = debounce(() => this.calcWidth(), 60);
		});
	},
})
export default class App extends Vue {}
