<script>
import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'Logout',
	created() {
		this.logout();
	},
})
export default class Logout extends Vue {
	logout() {
		this.$store.dispatch('auth/logout').then(() => {
			this.$router.push('/login');
		});
	}
}
</script>