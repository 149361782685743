import { defineComponent } from 'vue';
import { MDFTabs } from '@miraidesigns/tabs';
import { ChecklistData } from '@/types';
import axiosInstance from '../../axiosConfig';
import Dialog from '../Dialog/Dialog.vue';

interface LandingData {
	checklists: ChecklistData[];
	dialogId: number;
	isDialogActive1: boolean;
	isDialogActive2: boolean;
	open: number;
	complete: number;
	newReceived: number;
	username: string;
	selectedTab: string;
}

export default defineComponent({
	data() {
		return {
			checklists: [],
			dialogId: 0,
			isDialogActive1: false,
			isDialogActive2: false,
			open: 0,
			complete: 0,
			newReceived: 0,
			username: '',
			selectedTab: 'Dossiers à traiter',
		} as LandingData;
	},
	components: { Dialog },
	props: ['active', 'changes'],
	emits: ['refresh', 'back', 'view', 'message'],
	methods: {
		async populateData() {
			// Fetch checklists from the API.
			const response = await axiosInstance
				.get('/getListRepertoires')
				.catch(() => this.$router.push('/logout'));
			this.checklists = response.data;

			// Calculate the number of open and completed tasks.
			this.open = 0;
			this.complete = 0;
			this.newReceived = 0;
			this.checklists.forEach((checklist) => {
				if (checklist.status === 2) {
					this.complete++;
				} else if (checklist.status === 1) {
					this.open++;
				} else {
					this.newReceived++;
				}
			});
		},
		initTabs() {
			setTimeout(() => {
				// Look for the tabs container.
				const elem = document.querySelector('.mdf-tabs');

				if (elem) {
					new MDFTabs(elem);
				}
			}, 1000);
		},
		openDialog(id: number, dialogNb: number) {
			this['isDialogActive' + dialogNb] = true;
			this.dialogId = id;
		},
		closeDialog(dialogNb: number): void {
			this['isDialogActive' + dialogNb] = false;
		},
		confirmDialog(repStatus: number, dialogNb: number): void {
			this['isDialogActive' + dialogNb] = false;
			this.updateRepStatus(this.dialogId, repStatus);
		},
		async updateRepStatus(id: number, status: number) {
			const response = await axiosInstance.put(
				'/updateProjectStatus/' + id, {
					updatedAt: new Date().toISOString(),
					status,
				}
			);
			if (response.status === 200) {
				this.checklists.forEach((checklist) => {
					if (checklist.id === id) {
						checklist.status = status;
					}
				});
				this.$emit('message', `Le dossier ${this.checklists.find(item => item.id == id).rep} a été marqué comme ouvert.`);
			}
			this.$emit('refresh');
			this.$emit('back');
		}
	},
	computed: {
		hasData(): boolean {
			return this.checklists.length > 0;
		},
		getData(): ChecklistData[] {
			return this.checklists;
		},
		openReps(): number {
			return this.open;
		},
		completedReps(): number {
			return this.complete;
		},
		newReps(): number {
			return this.newReceived;
		},
		dialogActive1(): boolean {
			return this.isDialogActive1;
		},
		dialogActive2(): boolean {
			return this.isDialogActive2;
		},
	},
	watch: {
		changes: function() {
			this.populateData();
			this.initTabs();
		},
	},
	created() {
		this.populateData();
	},
	mounted() {
		const user = JSON.parse(localStorage.getItem('user'));
		if (user) this.username = user.username;

		this.$nextTick(() => {
			this.initTabs();
		});
	},
});
