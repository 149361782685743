import axiosInstance from '@/axiosConfig';

class AuthService {
	login(user) {
		return axiosInstance
			.post(`/getToken?login=${user.username}&password=${user.password}`, null, {
				  headers: {
				    'Content-Type': false
				  },
				  transformRequest: [(data, headers) => {
				    delete headers.common['Content-Type'];
				    return data;
				  }]
				})
			.then((response) => {
				if (response.data.token) {
					localStorage.setItem('user', JSON.stringify({ username: user.username }));
					localStorage.setItem('token', JSON.stringify(response.data.token));
				}

				return user;
			});
	}

	logout() {
		localStorage.removeItem('user');
		localStorage.removeItem('token');
	}
}

export default new AuthService();
