import {defineComponent} from "vue";
import {ChecklistData} from "@/types";
import axiosInstance from "../../axiosConfig";
import Dialog from "@/components/Dialog/Dialog.vue";
import Multiselect from 'vue-multiselect'

interface ViewData {
    checklistData: ChecklistData;
    tempData: ChecklistData;
    dialogId: number;
    isDialogActive1: boolean;
    isDialogActive2: boolean;
    markRequired: boolean;
    hasChanges: boolean;
}

export default defineComponent({
    components: {Dialog, Multiselect},
    data() {
        return {
            checklistData: this.data,
            tempData: null,
            dialogId: 0,
            isDialogActive1: false,
            isDialogActive2: false,
            markRequired: false,
            hasChanges: false
        } as ViewData;
    },
    props: ["active", "data"],
    emits: ["back", "refresh", "message", "attachments"],
    watch: {
        tempData: {
            handler(newVal, oldVal) {
                if (oldVal) this.hasChanges = true;
            },
            deep: true
        }
    },
    computed: {
        dialogActive1(): boolean {
            return this.isDialogActive1;
        },
        dialogActive2(): boolean {
            return this.isDialogActive2;
        }
    },
    methods: {
        async saveChanges() {
            let data = {
                "projectResponses": [{
                    id: this.checklistData.id,
                    updatedAt: new Date().toISOString(),
                    tasks: this.tempData.tasks.map(task => ({
                        'id': task.id,
                        "typeChamp": task.typeChamp,
                        "options": task.options,
                        "value": task.value,
                    }))
                }]
            };

            // Send the updated task status to api.
            const res = await axiosInstance.post("/saveProjectResponses", data);

            if (res.status === 200) {
                this.checklistData = JSON.parse(JSON.stringify(this.tempData));
                this.$emit("message", "modifications sauvegardées.");
            }
            this.hasChanges = false;
            this.$emit("refresh");
        },
        openDialog(id: number, dialogNb: number) {
            this["isDialogActive" + dialogNb] = true;
            this.dialogId = id;
        },
        closeDialog(dialogNb: number): void {
            this["isDialogActive" + dialogNb] = false;
        },
        async confirmDialog(dialogNb: number) {
            this["isDialogActive" + dialogNb] = false;
            if (dialogNb === 2)
                return this.$emit("callConfirmDialog", this.checklistData.id);

            let isCompleted = true;
            for (let task of this.checklistData.tasks) {
                if (!task.isRequired) continue;

                if ((task.typeChamp.startsWith("select") && !task.value.length) || (!task.typeChamp.startsWith("select") && !task.value[0])) {
                    isCompleted = false;
                    break;
                }
            }

            if (isCompleted) {
                const res = await axiosInstance.put(
                    "/updateProjectStatus/" + this.checklistData.id, {
                        updatedAt: new Date().toISOString(),
                        status: 2,
                    }
                );
                if (res.status === 200) {
                    this.checklistData.status = 2;
                    this.$emit("message", `Le dossier ${this.checklistData.rep} a été marqué comme clôturé.`);
                }
                this.$emit("back");
                this.$emit("refresh");
            } else {
                this.markRequired = true;
                this.$emit(
                    "message",
                    "Tous les tâches requises doivent être accomplies."
                );
            }
        }
    },
    created() {
        this.tempData = JSON.parse(JSON.stringify(this.checklistData));
    },
});