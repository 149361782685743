<template>
	<div class="col-md-12 mx-auto">
		<div class="card card-container mt-0">
			<img id="profile-img" :src="getLogo" class="profile-img-card" />
			<form name="form" @submit.prevent="handleLogin">
				<div class="form-group">
					<label for="username">Login</label>
					<input
						v-model="user.username"
						type="text"
						class="form-control mt-1"
						name="username"
						required
					/>
				</div>
				<div class="form-group">
					<label for="password">mot de passe</label>
					<input
						v-model="user.password"
						type="password"
						class="form-control mt-1"
						name="password"
						required
					/>
				</div>
				<div class="form-group mt-3">
					<button class="btn btn-primary btn-block" :disabled="loading">
            <span
				v-show="loading"
				class="spinner-border spinner-border-sm"
			></span>
						<span>se connecter</span>
					</button>
				</div>
				<div class="form-group mt-3">
					<div v-if="message" class="alert alert-danger" role="alert">
						{{ message }}
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import User from '../../models/user';
import { useRouter } from 'vue-router';

const router = useRouter();

@Options({
	name: 'Login',
	data() {
		return {
			user: new User('', ''),
			loading: false,
			message: '',
			logo: process.env.VUE_APP_LOGO,
		};
	},
	computed: {
		loggedIn() {
			return this.$store.state.auth.status.loggedIn;
		},
		getLogo() {
			return this.logo;
		},
	},
	created() {
		if (this.loggedIn) {
			this.$router.push('/');
		}
	},
	methods: {
		handleLogin() {
			this.loading = true;

			if (this.user.username && this.user.password) {
				this.$store.dispatch('auth/login', this.user).then(
					() => this.$router.push('/'),
					(error) => {
						this.loading = false;
						this.message =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();
					},
				);
			}
		},
	},
})
export default class Login extends Vue {
}
</script>

<style scoped>
label {
	display: block;
	margin-top: 10px;
}

.card-container.card {
	max-width: 350px !important;
	padding: 40px 40px;
}

.card {
	background-color: #f7f7f7;
	padding: 20px 25px 30px;
	margin: 0 auto 25px;
	margin-top: 50px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
	width: 156px;
	height: 160px;
	margin: 0 auto 10px;
	display: block;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}
</style>
