import en from '@vueform/vueform/locales/en'
import vueform from '@vueform/vueform/dist/vueform'
import axiosInstance from '@/axiosConfig';
import { defineConfig } from '@vueform/vueform'

export default defineConfig({
	theme: vueform,
	locales: { en },
	locale: 'en',
	endpoints: {
		uploadTempFile: async (value, el$) => {
			const formData = el$.form$.convertFormData({
				file: value,
			});

			const response = await axiosInstance.post('/addPieceJointe/' + el$.params.id, formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			});
			if (response.status === 200) el$.params.updateData(response.data.file);
		}
	}
})