import { createWebHistory, createRouter } from 'vue-router';
import Home from './components/Home/Home.vue';
import Login from './components/Authentication/Login.vue';
import Logout from './components/Authentication/Logout.vue';

const routes= [
	{ path: '/', component: Home },
	{ path: '/login', component: Login },
	{ path: '/logout', component: Logout },
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	const publicPages = ['/login'];
	const authRequired = !publicPages.includes(to.path);
	const loggedIn = localStorage.getItem('user');

	if (authRequired && !loggedIn) {
		next('/login');
	} else {
		next();
	}
});

export default router;
